// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'packs/richtext';

Rails.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", function() {
  let coll = document.getElementsByClassName("collapsible-card");
  let i;
  let flashMessage = document.querySelectorAll('.notification-container')[0];
  let burgerMenu = document.getElementById('burger-menu');
  let mobileMenu = document.getElementById('mobile-menu');

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      let content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }

  function fadeNotificationOut() {
    if (flashMessage) {
      flashMessage.classList.add("notification-invisible");
    }
  }

  const toggleMobileMenuDisplay = (target) => {
    if (target.style.display != "none") {
      target.style.display = "none";
    } else {
      target.style.display = "block";
    };
  };

  

  setTimeout(fadeNotificationOut, 5000);
  mobileMenu.style.display = 'none';
  burgerMenu.addEventListener("click", () => toggleMobileMenuDisplay(mobileMenu));
  
});

require("trix")
require("@rails/actiontext")
